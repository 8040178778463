import React, { useEffect, useState } from 'react'
// import {useNavigate, useLocation} from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap'
import { baseURL, walletBaseURL } from '../config/baseURLPath'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import './sponsorcss.css'
import GenderGraph from './charts/Gender'
import CGPA from './charts/CGPA'
import TopPrograms from './charts/TopPrograms'
import LevelOfAchievement from './charts/LevelOfAchievement'
import IndiaMap from './charts/IndiaMap'

const Dashboard = () => {
  const history = useHistory()
  const [dashboardData, setDashboardData] = useState('')

  const addNewProgram = () => {
    history.push('/sponsor/program-add')
  }

  const allocateBeneficiary = () => {
    history.push('/sponsor/beneficiary-list')
  }

  useEffect(() => {
    try {
      if (localStorage.getItem('userType') !== 'sponsor') {
        history.push(`/${localStorage.getItem('userType')}/dashboard`)
      }

      const userData = JSON.parse(localStorage.getItem('user'))
      getDashboard(userData.Sponsor.sponsor_id)
      getChartData(userData.Sponsor.sponsor_id)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const getChartData = (id) => {
    const url = baseURL + 'sponsor-chart-data/' + id

    axios({
      method: 'get',
      url,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }).then((response) => {
      console.log(response, 'Chart Response')
    })
  }

  const getDashboard = (id) => {
    const url = baseURL + 'getSponsorDashboard/' + id // Construct the URL

    console.log('URL:', url) // Log the URL to the console
    axios({
      method: 'get',
      url: baseURL + 'getSponsorDashboard/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response, 'Response')
        setDashboardData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="contentBox">
        <Row className="numbersRow">
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="red colored-box">
              <div className="number-box">
                <p className="numbersText">{(dashboardData && dashboardData?.ProgramCount) || 0}</p>
                <p className="sublabelText">Nos. of Programs</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="yellow colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData && dashboardData?.Beneficiaries) || 0}
                </p>
                <p className="sublabelText">Nos. of Beneficiaries</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="blue colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData && dashboardData?.CommittedFunds) || 0}
                </p>
                <p className="sublabelText">Funds Committed</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="green colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData && dashboardData?.DisbursedFunds) || 0}
                </p>
                <p className="sublabelText">Funds Disbursed</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <TopPrograms />
          </Col>
          <Col>
            <GenderGraph />
          </Col>
          <Col>
            <CGPA />
          </Col>
        </Row>
        <Row>
          <Col>
            <IndiaMap />
          </Col>
          <Col md={8}>
            <LevelOfAchievement />
          </Col>
        </Row>
        <Row>
          <Col md={3} xs={12} style={{ marginTop: '15px' }}>
            <p className="dashboard-section-title">CURRENT RUNNING PROGRAMS</p>
          </Col>
          <Col
            className="xs-only"
            md={3}
            xs={12}
            style={{
              marginBottom: '15px',
            }}
          >
            <Button
              onClick={addNewProgram}
              style={{ width: '100%' }}
              className="primaryAdminButton"
            >
              Add New Program
            </Button>
          </Col>
          <Col
            className="hide-xs"
            md={3}
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginBottom: '15px',
            }}
          ></Col>
          {/* <Col
            className="xs-only"
            style={{
              display: 'flex',
              marginBottom: '15px',
            }}
          > */}
          {/* <Button
              style={{ marginRight: '15px' }}
              onClick={() => history.push('/sponsor/program-list')}
              className="primaryAdminButton"
            >
              View Programs
            </Button> */}
          {/* <Button onClick={allocateBeneficiary} className="primaryAdminButton">
              View Beneficiaries
            </Button> */}
          {/* </Col> */}
          <Col
            className="hide-xs"
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginBottom: '15px',
            }}
          >
            <Button
              onClick={addNewProgram}
              className="primaryAdminButton"
              style={{ marginRight: '10px' }}
            >
              Add New Program
            </Button>
            <Button onClick={allocateBeneficiary} className="primaryAdminButton">
              View Beneficiaries
            </Button>
          </Col>
        </Row>
        <Row>
          {dashboardData && dashboardData?.RunningProgram.length > 0 ? (
            dashboardData?.RunningProgram.map((item, index) => (
              <Col sm={3} xs={12} key={index}>
                <MyPrograms item={item} />
              </Col>
            ))
          ) : (
            <Col>
              <div className="programBox p-4 flex justify-center items-center mb-2">
                <p className="programName">
                  Currently no program is running. Please select a curated program from the list
                  below or add a new program by clicking Add New Program.
                </p>
              </div>
            </Col>
          )}

          {/* {dashboardData && dashboardData?.RunningProgram.length === 4 && (
            <Col sm={12}>
              <button
                onClick={() => history.push('/sponsor/program-list')}
                className="btn btn-primary"
                style={{ backgroundColor: '#4b0082', borderColor: '#4b0082' }}
              >
                View All Programs
              </button>
            </Col>
          )} */}
        </Row>

        <Row>
          <Col style={{ marginTop: '15px' }}>
            <p className="dashboard-section-title">CURATED PROGRAMS</p>
          </Col>
        </Row>
        <Row className="numbersRowNoShadow">
          {dashboardData && dashboardData?.PredefinedPrograms?.length > 0 ? (
            dashboardData?.PredefinedPrograms.map((item, index) => {
              return (
                <Col sm={3} key={index}>
                  <PredefinedPrograms item={item} />
                </Col>
              )
            })
          ) : (
            <div>
              <div className="programBox p-4 flex justify-center items-center">
                <p className="programName">No Data available</p>
              </div>
            </div>
          )}
        </Row>
      </div>
    </>
  )
}

export default Dashboard

const MyPrograms = (props) => {
  const history = useHistory()
  return (
    <div className="my-program-card">
      <div className="header">{props.item.program_name}</div>
      {/* <div className="body">
        <p>
          {props.item.program_desc.length > 30
            ? `${props.item.program_desc.substring(0, 30)}...`
            : props.item.program_desc}
        </p>
      </div> */}
      <div className="footer">
        <div className="flex justify-center items-end">
          <p className="amount">₹{props.item.total_disbursed_amount}</p>
        </div>
        <div className="flex justify-center items-end">
          <span className="label mb-3">funds deployed</span>
        </div>
        <div className="flex">
          <div className="flex-1 flex items-center">
            <p className="duration-text">3 months left</p>
          </div>
          <div className="flex gap-2">
            <Button
              target="_blank"
              className="btn btn-sm confirm-payment"
              role="button"
              onClick={(event) => {
                event.preventDefault()
                history.push('/sponsor/program/' + props.item.program_id)
              }}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </Button>

            {props.item.fund_disbursed_yn === 'N' && (
              <Button
                className="btn btn-sm confirm-payment"
                role="button"
                onClick={(e) => {
                  e.stopPropagation()
                  history.push(
                    `/sponsor/finance-detail?sponsor_id=${props.item.sponsor_id}&program_id=${props.item.program_id}`,
                  )
                }}
              >
                Confirm Payment
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const PredefinedPrograms = (props) => {
  const history = useHistory()
  return (
    <div className="my-program-card">
      <div className="header">{props.item?.program_name}</div>
      {/* <div className="body">
        <p>{props?.item?.program_desc?.substring(0, 100)}</p>
      </div> */}
      <div className="body">
        <p>
          Target Beneficiaries:{' '}
          <b>{props?.item?.target_beneficiaries === '1' ? 'Students' : 'Ex-Servicemen'}</b>
        </p>
        <p>
          Program Type: <b>{props?.item?.program_type === '1' ? 'Skilling' : 'Scholarship'}</b>
        </p>
      </div>
      <div className="footer flex items-end justify-end">
        <Button
          className="btn btn-sm confirm-payment"
          role="button"
          onClick={() =>
            history.push('/sponsor/program-add?import_program=' + props?.item?.program_id)
          }
        >
          + Add
        </Button>
      </div>
    </div>
  )
}
