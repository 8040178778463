import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import './admincss.css'
import axios from 'axios'
import { baseURL } from '../../config/baseURLPath'
import logo from '../../components/HomeComponents/NavBar/Logo-new.png'
import VirtualAccountDetails from '../../components/VirtualAccountDetails'
const Header = () => {
  const history = useHistory()
  const [virtualAccountDetails, setVirtualAccountDetails] = useState(null)
  const logout = async (e) => {
    let loginid = JSON.parse(localStorage.getItem('user_id'))
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }
    await axios
      .post(
        baseURL + 'adminLogout',
        {
          admin_id: loginid,
        },
        jwtHeader,
      )
      .then(function (response) {
        if (response.data) {
          localStorage.removeItem('user')
          localStorage.removeItem('user_id')
          localStorage.removeItem('userType')
          localStorage.removeItem('csr_token')
          window.sessionStorage.clear()
          history.push('/')
        }
      })
      .catch(function (error) {
        console.log('error' + error)
        localStorage.removeItem('user')
        localStorage.removeItem('user_id')
        localStorage.removeItem('userType')
        localStorage.removeItem('csr_token')
        window.sessionStorage.clear()
        history.push('/')
      })
  }

  useEffect(() => {
    if (localStorage.getItem('csr_token') === null) {
      localStorage.removeItem('user')
      localStorage.removeItem('userType')
      localStorage.removeItem('user_id')
      localStorage.removeItem('csr_token')
      window.sessionStorage.clear()
      history.push('/')
    }
  }, [])

  const showDetails = () => {
    let userID = localStorage.getItem('user_id')
    setVirtualAccountDetails({ user_id: userID, user_type: 'sponsor' })
  }

  return (
    <div className="fixHeaderAdmin">
      <Row style={{ margin: 0 }}>
        <Col
          md={2}
          xs={12}
          className="d-flex align-items-center justify-content-start header-column"
        >
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/')}
            className="mid_pic img-fluid"
            alt="Resp#000000onsive "
            height={182}
            width={132}
            src={logo}
          />
        </Col>
        <Col md={6}></Col>
        <Col
          md={4}
          xs={12}
          className="rightMenuSection header-column"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <button
            style={{ border: 'none', background: 'none', marginRight: '15px' }}
            type="button"
            onClick={(e) => {
              history.push('/sponsor/dashboard')
            }}
            className="logoutLink"
          >
            Home
          </button>
          <button
            style={{ border: 'none', background: 'none', marginRight: '15px' }}
            type="button"
            onClick={() => showDetails()}
            className="logoutLink"
          >
            Virtual Account Details
          </button>
          <div className="user-name-div">
            <i className="fa fa-user-circle" aria-hidden="true" style={{ fontSize: '36px' }}></i>
            <span className="user-name">Hi, {localStorage.getItem('user_name')}</span>
          </div>
          <button
            style={{ border: 'none', background: 'none' }}
            type="button"
            onClick={(e) => {
              logout(e)
            }}
            className="logoutLink"
          >
            (Logout)
          </button>
        </Col>
      </Row>
      <VirtualAccountDetails
        show={virtualAccountDetails}
        handleClose={() => setVirtualAccountDetails(null)}
      />
    </div>
  )
}

export default Header
