import React, { useEffect, useState } from "react";

import axios from "axios";
import { baseURL } from "../../../config/baseURLPath";

import "../VirtualAccountDetails.css";
import StatusBox from "../../StatusBox";

const AddedBankAccounts = ({ show }) => {
  const [bankAccounts, setBankAccounts] = useState(null);

  useEffect(() => {
    if (show !== null) {
      fetchDetails();
    }
  }, [show]);

  const fetchDetails = async () => {
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };
    await axios
      .post(baseURL + "getAllAddedBankAccounts", show, jwtHeader)
      .then(function (response) {
        if (response.data?.Data?.success) {
          setBankAccounts(response.data?.Data?.data);
        } else {
          setBankAccounts({
            Message: response.data?.Message || "No record found on TrustMore",
          });
        }
      })
      .catch(function (error) {});
  };

  return (
    <div>
      {bankAccounts?.Message ? (
        <div className="flex justify-center items-center py-5">
          {bankAccounts?.Message}
        </div>
      ) : (
        <>
          <div className="flex">
            <div className="label-column">Name</div>
            <div className="label-column">Bank Name</div>
            <div className="label-column">Account Number</div>
            <div className="label-column">IFSC</div>
            <div
              className="label-column right-border justify-center"
              style={{ justifyContent: "center" }}
            >
              Status
            </div>
          </div>
          {bankAccounts?.map((account) => {
            return (
              <div className="flex" key={account?.id}>
                <div className="value-column no-right-border border-bottom">
                  {account?.name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {account?.bank_name}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {account?.bank_account}
                </div>
                <div className="value-column no-right-border border-bottom">
                  {account?.bank_ifsc}
                </div>
                <div className="value-column border-bottom">
                  <div className="flex justify-center items-center">
                    <StatusBox
                      statusList={{
                        active: { label: "Verified", bgColor: "#4caf50" },
                        inactive: { label: "Not-Verified", bgColor: "#fcba03" },
                      }}
                      statusValue={account?.verified ? "active" : "inactive"}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AddedBankAccounts;
