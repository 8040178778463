/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import '../admin.css'
import Swal from 'sweetalert2'
import { CFormCheck } from '@coreui/react'

const BANK_VA_DETAILS = {
  amount_committed: 0,
  account_balance: 0,
  future_payments: 0,
  surplus_in_virtual_account: 0,
  amount_to_be_added_va: 0,
}

const CommitFunds = (props) => {
  const history = useHistory()
  const [funds, setFunds] = useState(props.program)
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }
  const [course, setCourse] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [useSurplusAmount, setUseSurplusAmount] = useState(false)
  const [virtualBalanceDetails, setVirtualBalanceDetails] = useState(BANK_VA_DETAILS)

  useEffect(() => {
    if (props?.program?.course_id) {
      fetchCourse(props?.program?.course_id)
    }
    if (props.programType === '') {
      Swal.fire('Select a program type first.', '', 'error').then(() => props.setActiveTab(2))

      return
    }
  }, [])

  const fetchCourse = (id) => {
    if (!id) return
    axios
      .get(baseURL + 'getCourseById/' + id, jwtHeader)
      .then((response) => {
        setCourse(response.data?.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const addProgram = () => {
    // Disable button on click
    if (isSubmitting) return

    let values_option

    if (props.programType === '0') {
      values_option = {
        ...funds,
        predefined_program_ind: 'N',
        program_status: '1',
        course,
        eligibleCandidates: JSON.parse(localStorage.getItem('eligibleCandidates')).slice(
          0,
          props?.program?.sponsored_candidate_count,
        ),
        program_duration: props?.programDuration,
        amount_to_start_program: props?.amountToBePaidToStartProgram,
        amount_per_student: props?.amountPerStudent,
        cgpa: props.selectedCGPA,
        wallet_setup: props.selectedMobileWallet,
        financial_wellness: props.selectedFW,
        twelveth_marks: props.selected12th,
        family_income: props.selectedFI,
        vendor_id: props.vendor_id,
        carry_forward_funds: props.isCarryForwardFunds,
        skill_id: props.skill_id,
      }
    }

    if (props.programType === '1') {
      values_option = {
        ...funds,
        predefined_program_ind: 'N',
        program_status: '1',
        course,
        eligibleCandidates: JSON.parse(localStorage.getItem('eligibleCandidates')),
        program_duration: props?.programDuration,
        amount_to_start_program: props?.amountToBePaidToStartProgram,
        amount_per_student: props?.amountPerStudent,
        cgpa: props.selectedCGPA,
        wallet_setup: props.selectedMobileWallet,
        financial_wellness: props.selectedFW,
        twelveth_marks: props.selected12th,
        family_income: props.selectedFI,
        carry_forward_funds: props.isCarryForwardFunds,
      }

      values_option['total_amount'] = props?.amountToBePaidToStartProgram
    }
    if (values_option?.program_type === '0' && values_option?.eligibleCandidates.length === 0) {
      Swal.fire('No beneficiaries!.', 'No beneficiaries select!', 'error')
      return
    }

    let balanceAmount = useSurplusAmount
      ? virtualBalanceDetails?.amount_to_be_added_va
      : virtualBalanceDetails?.amount_committed

    setIsSubmitting(true)
    if (props.program.program_id) {
      axios({
        method: 'POST',
        url: baseURL + 'setProgram',
        data: values_option,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then(async (response) => {
          setIsSubmitting(false)
          localStorage.removeItem('eligibleCandidates')
          alert('New program created successfully.')

          // Extract sponsor_id and program_id from the response
          const sponsor_id = response.data.Content.sponsor_id
          const program_id = response.data.Content.program_id
          const course_id = values_option?.course_id || ''

          let response2 = await axios({
            method: 'POST',
            url: baseURL + 'addProgramCommittedFunds',
            data: [
              {
                sponsor_id,
                program_id,
                course_id,
                amount: values_option?.total_amount,
              },
            ],
            headers: { 'access-token': localStorage.getItem('csr_token') },
          })

          if (response2) {
            // Redirect to the finance_detail page with sponsor_id and program_id
            history.replace(
              `/sponsor/finance-detail?sponsor_id=${sponsor_id}&program_id=${program_id}`,
            )
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          console.error(error)
        })
    } else {
      axios({
        method: 'POST',
        url: baseURL + 'addProgram',
        data: values_option,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then(async (response) => {
          localStorage.removeItem('eligibleCandidates')

          const sponsor_id = response.data.Content.sponsor_id
          const program_id = response.data.Content.program_id
          const course_id = values_option?.course_id || ''

          if (balanceAmount !== '0 (Sufficient Amount)') {
            Swal.fire('Success!.', 'New program created successfully.', 'success')
            history.replace(
              `/sponsor/finance-detail?sponsor_id=${sponsor_id}&program_id=${program_id}`,
            )
            return
          }

          let response2 = await axios({
            method: 'POST',
            url: baseURL + 'addProgramCommittedFunds',
            data: [
              {
                sponsor_id,
                program_id,
                course_id,
                amount: values_option?.amountToBePaidToStartProgram,
                use_surplus_amount: useSurplusAmount,
              },
            ],
            headers: { 'access-token': localStorage.getItem('csr_token') },
          })

          if (response2) {
            history.replace('/sponsor/dashboard')
            Swal.fire('Success!.', 'New program created successfully', 'success')
            setIsSubmitting(false)
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          console.error(error)
        })
    }
  }

  const finalActionButtonText = () => {
    if (
      useSurplusAmount &&
      virtualBalanceDetails?.amount_to_be_added_va === '0 (Sufficient Amount)'
    ) {
      return 'Submit & Pay from Virtual Account'
    } else if (
      useSurplusAmount &&
      virtualBalanceDetails?.amount_to_be_added_va !== '0 (Sufficient Amount)'
    ) {
      return 'Submit'
    } else if (!useSurplusAmount) {
      return 'Submit'
    }
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Name</p>
              </Col>
              <Col sm={9}>
                <p className="valueText">{funds?.program_name}</p>
              </Col>
            </Row>
            <Row style={{ borderBottom: '1px solid #ccc' }}>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Details</p>
              </Col>
              <Col sm={9}>
                <p className="valueText">{funds?.program_desc}</p>
              </Col>
            </Row>

            {props.programType === '1' && props.paymentOption === 'monthly' && (
              <Row style={{ borderBottom: '1px solid #ccc' }}>
                <Col sm={3} style={{ backgroundColor: '#002347' }}>
                  <p className="labelText">Program Duration</p>
                </Col>
                <Col sm={9}>
                  <p className="valueText">{props?.programDuration} Months</p>
                </Col>
              </Row>
            )}
            <CommittedFunds
              item={funds}
              {...props}
              useSurplusAmount={useSurplusAmount}
              setUseSurplusAmount={setUseSurplusAmount}
              virtualBalanceDetails={virtualBalanceDetails}
              setVirtualBalanceDetails={setVirtualBalanceDetails}
            />
          </Card.Body>
        </Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
          <Button onClick={addProgram} className="primaryAdminButton" disabled={isSubmitting}>
            {/* {isSubmitting ? 'Submitting...' : 'Submit'} */}
            {isSubmitting ? (
              <img alt="spinner" src="/loaders/spinner_red.gif" style={{ height: '20px' }} />
            ) : (
              finalActionButtonText()
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default CommitFunds

const CommittedFunds = (props) => {
  useEffect(() => {
    if (props.item?.course_id !== '') {
      fetchCourse(props.item?.course_id)
    }
    fetchVirtualAccountDetails()
  }, [])

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const fetchVirtualAccountDetails = async () => {
    let userID = localStorage.getItem('user_id')
    let obj = {
      user_id: userID,
      user_type: 'sponsor',
      pending_payment_amount: true,
    }
    await axios
      .post(baseURL + 'fetchVirtualAccountDetails', obj, jwtHeader)
      .then(function (response) {
        if (response.data?.Data?.success) {
          let accountBalance = parseFloat(response.data?.Data?.data?.balance || 0)
          let futurePayments = parseFloat(response?.data?.PendingPayment[0]?.future_payments || 0)
          let surplusToVA = accountBalance - futurePayments
          let amountCommitted = props.isCarryForwardFunds
            ? parseFloat(props.plannedBudget || 0)
            : parseFloat(props.amountToBePaidToStartProgram || 0)
          let amountToBeAddedInVA = amountCommitted - surplusToVA
          let ele = {
            account_balance: accountBalance,
            future_payments: futurePayments,
            surplus_in_virtual_account: surplusToVA,
            amount_to_be_added_va:
              amountToBeAddedInVA < 0 ? `0 (Sufficient Amount)` : amountToBeAddedInVA,
            amount_committed: amountCommitted,
          }
          props.setVirtualBalanceDetails(ele)
        } else {
        }
      })
      .catch(function (error) {})
  }

  const [course, setCourse] = useState({})
  const fetchCourse = (id) => {
    axios
      .get(baseURL + 'getCourseById/' + id, jwtHeader)
      .then((response) => {
        setCourse(response.data?.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347' }}>
          <p className="labelText">Program Type</p>
        </Col>
        <Col sm={9}>
          <p className="valueText">{props.programType === '0' ? 'Skilling' : 'Scholarship'}</p>
        </Col>
      </Row>

      {props.programType === '1' && (
        <Row>
          <Col sm={3} style={{ backgroundColor: '#002347' }}>
            <p className="labelText">Beneficiaries being sponsored</p>
          </Col>
          <Col sm={9}>
            <p className="valueText">{props.item?.eligible_candidates_count}</p>
          </Col>
        </Row>
      )}
      {props.programType === '0' && (
        <>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Beneficiaries being sponsored</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{props.item?.sponsored_candidate_count}</p>
            </Col>
          </Row>

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Name</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{course?.course_name}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Description</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{course?.course_desc}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Fee</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">&#x20B9; {course?.fees}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Duration</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">
                {course?.duration} {course?.duration_unit}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Service Partner</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{course?.institute_name}</p>
            </Col>
          </Row>
        </>
      )}
      <Row style={{ borderTop: '1px solid #ccc' }}>
        <Col sm={3} style={{ backgroundColor: '#002347' }}>
          <p className="labelText">Amount Committed</p>
        </Col>
        <Col sm={9}>
          <p className="valueText">&#x20B9; {props.virtualBalanceDetails?.amount_committed}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347' }}>
          <p className="labelText">Surplus in Virtual Account</p>
        </Col>
        <Col sm={9}>
          <div className="flex items-center">
            <p className="valueTextNormal">
              &#x20B9; {props.virtualBalanceDetails?.surplus_in_virtual_account}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347' }} className="pt-4">
          <p className="labelText">Would you like to use the surplus amount?</p>
        </Col>
        <Col sm={9} className="pt-4">
          <div className="flex items-center gap-4">
            <div className="flex justify-center items-center gap-2">
              <CFormCheck
                type="radio"
                name="radio-stacked"
                id="validationFormCheck2"
                label=""
                checked={props.useSurplusAmount}
                onClick={() => props.setUseSurplusAmount(true)}
                onChange={() => null}
              />
              <b style={{ marginBottom: '-5px' }}>Yes</b>
            </div>
            <div className="flex justify-center items-center gap-2">
              <CFormCheck
                type="radio"
                name="radio-stacked"
                id="validationFormCheck2"
                label=""
                checked={!props.useSurplusAmount}
                onClick={() => props.setUseSurplusAmount(false)}
                onChange={() => null}
              />
              <b style={{ marginBottom: '-5px' }}>No</b>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347' }} className="pt-4">
          <p className="labelText">Amount to be added to VA</p>
        </Col>
        <Col sm={9} className="pt-4">
          <p className="valueText">
            &#x20B9;{' '}
            {props.useSurplusAmount
              ? props.virtualBalanceDetails?.amount_to_be_added_va
              : props.virtualBalanceDetails?.amount_committed}
          </p>
        </Col>
      </Row>
    </>
  )
}
