import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import axios from "axios";
import { baseURL } from "../../../config/baseURLPath";

import "../VirtualAccountDetails.css";

const VirtualAccount = ({ show }) => {
  const [virtualAccountDetails, setVirtualAccountDetails] = useState(null);

  useEffect(() => {
    if (show !== null) {
      fetchDetails();
    }
  }, [show]);

  const fetchDetails = async () => {
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };
    await axios
      .post(baseURL + "fetchVirtualAccountDetails", show, jwtHeader)
      .then(function (response) {
        if (response.data?.Data?.success) {
          setVirtualAccountDetails(response.data?.Data?.data);
        } else {
          setVirtualAccountDetails({
            Message: response.data?.Message || "No record found on TrustMore",
          });
        }
      })
      .catch(function (error) {});
  };

  return (
    <div>
      {virtualAccountDetails?.Message ? (
        <div className="flex justify-center items-center py-5">
          {virtualAccountDetails?.Message}
        </div>
      ) : (
        <>
          <div className="flex">
            <div className="label-column">Name</div>
            <div className="value-column">{virtualAccountDetails?.name}</div>
          </div>
          <div className="flex">
            <div className="label-column">Account Number</div>
            <div className="value-column">
              {virtualAccountDetails?.acccount_number}
            </div>
          </div>
          <div className="flex">
            <div className="label-column">Balance</div>
            <div className="value-column">
              ₹{virtualAccountDetails?.balance}
            </div>
          </div>
          <div className="flex">
            <div className="label-column">Bank Name</div>
            <div className="value-column">
              {virtualAccountDetails?.bank_name}
            </div>
          </div>
          <div className="flex">
            <div className="label-column border-bottom">IFSC Code</div>
            <div className="value-column border-bottom">
              {virtualAccountDetails?.ifsc_code}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VirtualAccount;
