/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import '../admin.css'
import swal from 'sweetalert2'
import { propTypes } from 'react-bootstrap/esm/Image'
const AddProgram = (props) => {
  const history = useHistory()
  const params = useParams()
  const [values, setValues] = useState({
    program_status: '1',
    program_name: '',
    program_desc: '',
    sponsor_name: '',
    program_type: '0',
    target_beneficiaries: '',
    vendor_id: '',
    sponsor_id: '',
    course_id: '',
    course_fee: '',
    course_duration: '',
    total_amount: '',
    total_candidates_available: 0,
    eligible_candidates_count: 0,
  })

  const [filter, setFilter] = useState({
    skill_id: '',
    ig_id: '',
    sport_id: '',
    pc_id: '',
    current_state_id: '',
    city_id: '',
    college_id: '',
    gender: '',
  })
  const [parentData, setParentData] = useState(null)
  const handleFilter = (e) => {
    const { name, value } = e.target
    let prev = { ...filter }

    if (name === 'current_state_id') {
      prev[name] = value
      prev['city_id'] = ''
      prev['college_id'] = ''
      fetchCities(value)
      fetchCollege(value)
    } else {
      prev[name] = value
    }
    setFilter(prev)
    applyFilter(prev)
  }

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const applyFilter = (filterValues) => {
    axios({
      method: 'POST',
      url: baseURL + 'getEligibleCandidates',
      data: filterValues,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response)
        setValues((prev) => ({
          ...prev,
          ['eligible_candidates_count']: response.data.EligibleCandidates[0].eligibleCandidates,
          ['total_candidates_available']: response.data.TotalCandidates[0].totalCandidates,
        }))
      })
      .catch((error) => {
        console.log(error)
        // alert('Something went wrong. Please try again.')
      })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'current_state_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['city_id']: '',
      }))
      fetchCities(value)
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    fetchState()
    fetchCriteria()
    fetchIncomeGroup()
    // fetchSports()
    // fetchSkills()
    // fetchVendor()
    // fetchCourses()

    console.log(params)
    if (params.id !== 0) {
      fetchProgram(params.id)
    }
  }, [])

  const [activeStep, setActiveStep] = useState(2)

  const [stateList, setStateList] = useState([])
  const fetchState = () => {
    axios
      .get(baseURL + 'getStateList', jwtHeader)
      .then((response) => {
        setStateList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Vendor
  const [vendorList, setVendorList] = useState([])
  const fetchVendor = () => {
    axios({
      method: 'get',
      url: baseURL + 'getVendor',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setVendorList(response.data.Content)
      })
      .catch((error) => {})
  }

  const [courseList, setCourseList] = useState([])

  const fetchCourses = async (id) => {
    await axios({
      method: 'get',
      url: baseURL + 'getCoursesBySubject/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response)
        setCourseList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // College
  const [collegeList, setCollegeList] = useState([])
  const fetchCollege = (current_state_id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCollegeMaster/' + current_state_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        // console.log(response)
        setCollegeList(response.data.Content)
      })
      .catch((error) => {})
  }

  // Performace Criteria
  const [criteriaList, setCriteriaList] = useState([])
  const fetchCriteria = () => {
    axios
      .get(baseURL + 'getPerformanceCriteria', jwtHeader)
      .then((response) => {
        setCriteriaList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Income Group
  const [incomeGroupList, setIncomeGroupList] = useState([])
  const fetchIncomeGroup = () => {
    axios
      .get(baseURL + 'getIncomeGroup', jwtHeader)
      .then((response) => {
        setIncomeGroupList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Sports List
  const [sportsList, setSportsList] = useState([])
  const fetchSports = () => {
    axios
      .get(baseURL + 'getSports', jwtHeader)
      .then((response) => {
        setSportsList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Skills List
  const [skillList, setSkillList] = useState([])
  const fetchSkills = () => {
    axios
      .get(baseURL + 'getSubjects', jwtHeader)
      .then((response) => {
        setSkillList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // City List
  const [cityList, setCityList] = useState([])
  const fetchCities = (current_state_id) => {
    axios
      .get(baseURL + 'getCity/' + current_state_id, jwtHeader)
      .then((response) => {
        setCityList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [totalCandidates, setTotalCandidates] = useState([])
  const fetchTotalCandidates = () => {
    axios
      .get(baseURL + 'getTotalCandidates', jwtHeader)
      .then((response) => {
        setCityList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const successAction = () => {
    let values_option = { ...values, ...filter }
    if (
      values_option.program_name.trim() === '' ||
      values_option.sponsor_name === null ||
      values_option.sponsor_name?.trim() === '' ||
      values_option.target_beneficiaries === ''
    ) {
      alert('Please fill required fields.')
    } else {
      if (props.id === 0) {
        props.setProgram(values_option)
        // axios({
        //   method: 'POST',
        //   url: baseURL + `addProgram`,
        //   data: values_option,
        //   headers: { 'access-token': localStorage.getItem('csr_token') },
        // })
        //   .then((response) => {
        //     // console.log(response)
        //     props.setProgramId(response.data.Content.program_id)
        //   })
        //   .catch((error) => {
        //     console.log(error)
        //     // alert('Something went wrong. Please try again.')
        //   })
      } else {
        values_option.program_id = props.id
        console.log(values_option)
        // return;
        axios({
          method: 'POST',
          url: baseURL + 'setProgram',
          data: values_option,
          headers: { 'access-token': localStorage.getItem('csr_token') },
        })
          .then((response) => {
            // console.log(response)
            // props.setProgramId(response.data.Content.program_id)
            alert('Successfully updated')
          })
          .catch((error) => {
            console.log(error)
            // alert('Something went wrong. Please try again.')
          })
      }
    }
  }

  const fetchProgram = (id) => {
    axios
      .get(baseURL + 'getProgramById/' + id, jwtHeader)
      .then((response) => {
        console.log(response.data.Content[0])
        setValues(response.data.Content[0])
        setFilter({
          skill_id: response.data.Content[0].skill_id,
          ig_id: response.data.Content[0].ig_id,
          sport_id: response.data.Content[0].sport_id,
          pc_id: response.data.Content[0].pc_id,
          current_state_id: response.data.Content[0].current_state_id,
          city_id: response.data.Content[0].city_id,
          college_id: response.data.Content[0].college_id,
          gender: response.data.Content[0].gender,
          // permanent_current_state_id:response.data.Content[0].permanent_current_state_id,
        })

        fetchCities(response.data.Content[0].current_state_id)
        fetchCollege(response.data.Content[0].current_state_id)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Name *</p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="program_name"
                    placeholder="Program Name"
                    value={values.program_name}
                    onChange={handleChange}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Description</p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="program_desc"
                    placeholder="Program Description"
                    value={values.program_desc}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Sponsored By *</p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    readOnly={
                      parentData === null
                        ? false
                        : parentData.sponsor_catg_type === 'NGO'
                        ? false
                        : true
                    }
                    name="sponsor_name"
                    placeholder="Sponsor Name"
                    value={values.sponsor_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Target Beneficiaries *</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="target_beneficiaries"
                    disabled
                    value={values.target_beneficiaries}
                    onChange={handleChange}
                  >
                    <option value="">Select Beneficiaries</option>
                    <option value="1">Students</option>
                    <option value="2">Ex-Servicemen</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Selection Criteria</p>
              </Col>
              <Col sm={9}>
                <p className="formLabelText">Location Criteria</p>
                <Row style={{ borderBottom: '1px solid #ccc' }}>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>State</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="current_state_id"
                        disabled
                        value={values.current_state_id}
                        onChange={handleFilter}
                      >
                        <option value="">State</option>
                        {stateList?.map((stateids) => (
                          <option value={stateids.state_id} key={stateids.state_id}>
                            {stateids.state_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>City</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="city_id"
                        disabled
                        value={values.city_id}
                        onChange={handleFilter}
                      >
                        {cityList?.map((item) => (
                          <option value={item.city_id} key={item.city_id}>
                            {item.city_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Educational Institutions Name</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="college_id"
                        disabled
                        value={values.college_id}
                        onChange={handleFilter}
                      >
                        {collegeList?.map((item) => (
                          <option value={item.college_id} key={item.college_id}>
                            {item.college_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <p className="formLabelText">Personal Criteria</p>
                <Row style={{ borderBottom: '1px solid #ccc' }}>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Gender</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="gender"
                        disabled
                        value={filter.gender}
                        onChange={handleFilter}
                      >
                        <option value="">Select</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Income Group (Annual)</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="ig_id"
                        disabled
                        value={filter.ig_id}
                        onChange={handleFilter}
                      >
                        <option value="">Select</option>
                        {incomeGroupList?.map((item) => {
                          return (
                            <option key={item.ig_id} value={item.ig_id}>
                              {item.ig_name}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <Col md={4}>
                  <span style={{fontSize:"12px"}}>Home State</span>
                  <Form.Group className="mb-3">
                    <Form.Select name="permanent_current_state_id" value={filter.permanent_current_state_id} onChange={handleFilter}>
                      <option value="">Select</option>
                      {stateList?.map((stateids) => (
                        <option value={stateids.current_state_id} key={stateids.current_state_id}>
                          {stateids.state_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col> */}
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Performance Criteria (CGPA)</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="pc_id"
                        disabled
                        value={filter.pc_id}
                        onChange={handleFilter}
                      >
                        <option value="">Select</option>
                        {criteriaList?.map((item) => {
                          return (
                            <option key={item.pc_id} value={item.pc_id}>
                              {item.pc_name}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {/* <p className="formLabelText">Performance Based</p>          
              <Row>
                
                <Col md={4}>
                  <span style={{fontSize:"12px"}}>Sports</span>      
                  <Form.Group className="mb-3">
                    <Form.Select name="sport_id" value={filter.sport_id} onChange={handleFilter} disabled>
                      <option value="">Select</option>
                      {sportsList?.map((item)=>{
                        return(
                          <option key={item.sport_id} value={item.sport_id}>{item.sport_name}</option>    
                        )
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <span style={{fontSize:"12px"}}>Skills</span>
                  <Form.Group className="mb-3">
                    <Form.Select name="skill_id" value={filter.skill_id} onChange={handleFilter} disabled>
                      <option value="">Select</option>
                      {skillList?.map((item)=>{
                        return(
                          <option key={item.skill_id} value={item.skill_id}>{item.skill_name}</option>    
                        )
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col> 
              </Row> */}
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Eligible Candidates Count</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    readOnly
                    type="text"
                    name="eligible_candidates_count"
                    placeholder="0"
                    value={values.eligible_candidates_count}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Total Candidates Available</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    readOnly
                    type="text"
                    name="total_candidates_available"
                    placeholder="0"
                    value={values.total_candidates_available}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <CreateProgram id={params.id} />
      <div className="container">
        <Button className="btn primaryAdminButton" role="button" onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
    </>
  )
}

export default AddProgram

const CreateProgram = (props) => {
  const history = useHistory()
  const [values, setValues] = useState({
    planned_budget: 0,
    balance_budget: 0,
    total_budget: 0,
    eligible_candidates_count: 0,
  })

  const blankProgram = {
    program_type: '0',
    subject_id: '',
    skill_id: '',
    course_id: '',
    vendor_id: '',
    additional_expense: 0,
    seeking_scholarship: 0,
    sponsored_candidate_count: 0,
    total_amount: 0,
  }

  const sorting_parameters = {
    cgpa: false,
    financial_wellness: false,
    mobile_wallet_setup: false,
    twelth_marks: false,
    family_income: false,
    gender: false,
  }

  const [totalBudgetEmarked, setTotalBudgetEmarked] = useState(0)
  const [balanceBudget, setBalanceBudget] = useState(0)
  const [plannedBudget, setPlannedBudget] = useState(0)

  const [programs, setPrograms] = useState([blankProgram])
  const [sorting, setSorting] = useState(sorting_parameters)

  useEffect(() => {
    let balance = plannedBudget - totalBudgetEmarked
    setBalanceBudget(balance)
    setValues({
      ...values,
      planned_budget: plannedBudget,
      balance_budget: balance,
      total_budget: totalBudgetEmarked,
    })
  }, [plannedBudget, totalBudgetEmarked])

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    console.log(props.id)
    console.log(props.program)
    if (props.id !== 0) {
      let res = fetchProgram(props.id)
      console.log(res, 'RES')
    } else {
      setValues(props.program)
      if (props.program.planned_budget !== undefined && props.program.planned_budget !== '') {
        // setValues(response.data.Content[0])
        setPlannedBudget(props.program.planned_budget)
        setTotalBudgetEmarked(props.program.total_amount)

        const blankProgram = {
          program_type: props.program.program_type,
          subject_id: props.program.subject_id,
          skill_id: props.program.skill_id,
          course_id: props.program.course_id,
          vendor_id: props.program.vendor_id,
          additional_expense: props.program.additional_expense,
          seeking_scholarship: 0,
          sponsored_candidate_count: props.program.sponsored_candidate_count,
          total_amount: props.program.total_amount,
        }
        setPrograms([blankProgram])
      }
    }

    fetchIncomeGroup()
  }, [])

  const [activeStep, setActiveStep] = useState(2)

  // Income Group
  const [incomeGroupList, setIncomeGroupList] = useState([])
  const fetchIncomeGroup = () => {
    axios
      .get(baseURL + 'getIncomeGroup', jwtHeader)
      .then((response) => {
        setIncomeGroupList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addProgram = (values_option) => {
    if (props.id === 0) {
      props.setProgram(values_option)
    } else {
      values_option.program_id = props.id
      axios({
        method: 'POST',
        url: baseURL + 'setProgram',
        data: values_option,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then((response) => {
          alert('Successfully updated')
        })
        .catch((error) => {
          console.log(error)
          // alert('Something went wrong. Please try again.')
        })
    }

    // axios({
    //   method: 'POST',
    //   url: baseURL + `addProgram`,
    //   data: values_option,
    //   headers: { 'access-token': localStorage.getItem('csr_token') },
    // })
    //   .then((response) => {
    //     // console.log(response)
    //     props.setProgramId(response.data.Content.program_id)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     // alert('Something went wrong. Please try again.')
    //   })
  }

  const successAction = () => {
    // console.log(programs)
    let program_list = [...programs]
    for (let i = 0; i < program_list.length; i++) {
      let combined_values = { ...values, ...program_list[i] }
      addProgram(combined_values)
    }
  }

  const fetchProgram = (id) => {
    axios
      .get(baseURL + 'getProgramById/' + id, jwtHeader)
      .then((response) => {
        setValues(response.data.Content[0])
        setPlannedBudget(response.data.Content[0].planned_budget)
        setTotalBudgetEmarked(response.data.Content[0].total_amount)

        const blankProgram = {
          program_type: response.data.Content[0].program_type,
          subject_id: response.data.Content[0].subject_id,
          skill_id: response.data.Content[0].skill_id,
          course_id: response.data.Content[0].course_id,
          vendor_id: response.data.Content[0].vendor_id,
          additional_expense: response.data.Content[0].additional_expense,
          seeking_scholarship: 0,
          sponsored_candidate_count: response.data.Content[0].sponsored_candidate_count,
          total_amount: response.data.Content[0].total_amount,
        }
        setPrograms([blankProgram])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addAnotherProgram = () => {
    let programList = [...programs]
    programList.push(blankProgram)
    setPrograms(programList)
  }

  const removeProgram = (index) => {
    let programList = [...programs]
    programList.splice(index, 1)

    setPrograms(programList)
  }

  const handleProgramValue = (name, value, index) => {
    let program_list = [...programs]
    let program = { ...program_list[index] }
    program[name] = value
    program_list[index] = program

    if (name === 'total_amount') {
      let totalBudgetEmarked = 0
      program_list.map((item) => {
        totalBudgetEmarked = totalBudgetEmarked + value
      })
      setTotalBudgetEmarked(totalBudgetEmarked)
    }
    setPrograms(program_list)
  }

  const handleSorting = (name, value) => {
    setSorting((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Target Beneficiaries</p>
              </Col>
              <Col sm={9}>
                <p className="valueText">
                  {values?.target_beneficiaries === '1' ? 'Students' : 'Ex-Serviceman'}
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Sponsored Beneficiaries</p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Form.Group className="mb-3">
                  {values.program_type === '0' ? (
                    <Form.Control
                      type="text"
                      name="sponsored_candidate_count"
                      value={values.sponsored_candidate_count}
                      onChange={handleChange}
                      readOnly
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      name="sponsored_candidate_count"
                      value={values.eligible_candidates_count}
                      onChange={handleChange}
                      readOnly
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Eligible Beneficiaries</p>
              </Col>
              <Col sm={9}>
                <p className="valueText">
                  {values?.eligible_candidates_count === undefined
                    ? 0
                    : values?.eligible_candidates_count}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Planned Budget</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="planned_budget"
                    placeholder="0"
                    value={plannedBudget}
                    readOnly
                    onChange={(e) => setPlannedBudget(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {programs.map((item, index) => {
              return (
                <ProgramSetup
                  key={index}
                  program={values}
                  values={item}
                  addAnotherProgram={() => addAnotherProgram()}
                  removeProgram={() => removeProgram(index)}
                  programLength={programs.length}
                  handleValue={(name, value) => handleProgramValue(name, value, index)}
                />
              )
            })}
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', borderTop: '1px solid #ffffff' }}>
                <p className="labelText"></p>
              </Col>
              <Col sm={9} style={{ borderTop: '1px solid #000000', paddingTop: '10px' }}>
                <p className="valueText">Selection Criteria (Multiple items can be selected)</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">CGPA</p>
              </Col>
              <Col sm={9}>
                <Row>
                  {/* <Col
                  sm={1}
                  style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
                >
                  <Form.Check
                    type={'checkbox'}
                    checked={sorting.cgpa ? true : false}
                    defaultChecked={true}
                    onChange={() => handleSorting('cgpa', !sorting.cgpa)}
                    style={{ width: '25px', height: '25px' }}
                  />
                </Col> */}

                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      {/* <Form.Control
                      type="text"
                      name="cgpa_input" 
                      placeholder="0"
                      value={values.cgpa_input} 
                      onChange={handleChange} 
                    /> */}

                      <Form.Select
                        name="cgpa_input"
                        value={values.cgpa}
                        onChange={handleChange}
                        disabled
                      >
                        <option value="">Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Mobile Wallet Setup</p>
              </Col>
              <Col sm={9}>
                <Row>
                  {/* <Col
                  sm={1}
                  style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
                >
                  <Form.Check
                    type={'checkbox'}
                    defaultChecked={true}
                    checked={sorting.mobile_wallet_setup ? true : false}
                    onChange={() =>
                      handleSorting('mobile_wallet_setup', !sorting.mobile_wallet_setup)
                    }
                    style={{ width: '25px', height: '25px' }}
                  />
                </Col> */}
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      {/* <Form.Control
                      type="text"
                      name="mobile_wallet_input"
                      placeholder="0"
                      value={values.mobile_wallet_input}
                      onChange={handleChange}
                    /> */}
                      <Form.Select
                        name="cgpa_input"
                        value={values.wallet_setup}
                        onChange={handleChange}
                        disabled
                      >
                        <option value="">Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Financial Wellness</p>
              </Col>
              <Col sm={9}>
                <Row>
                  {/* <Col
                  sm={1}
                  style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
                >
                  <Form.Check
                    type={'checkbox'}
                    checked={sorting.financial_wellness ? true : false}
                    defaultChecked={true}
                    onChange={() =>
                      handleSorting('financial_wellness', !sorting.financial_wellness)
                    }
                    style={{ width: '25px', height: '25px' }}
                  />
                </Col> */}
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      {/* <Form.Control
                      type="text"
                      name="financial_wellnesss_input"
                      placeholder="0"
                      value={values.financial_wellness_input}
                    /> */}
                      <Form.Select
                        name="cgpa_input"
                        value={values.financial_wellness}
                        disabled
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">12th Marks</p>
              </Col>
              <Col sm={9}>
                <Row>
                  {/* <Col
                  sm={1}
                  style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
                >
                  <Form.Check
                    type={'checkbox'}
                    checked={sorting.twelth_marks ? true : false}
                    defaultChecked={true}
                    onChange={() => handleSorting('twelth_marks', !sorting.twelth_marks)}
                    style={{ width: '25px', height: '25px' }}
                  />
                </Col> */}
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      {/* <Form.Control
                      type="text"
                      name="institute_name"
                      placeholder="0"
                      value={values.institute_name}
                      onChange={handleChange}
                    /> */}

                      <Form.Select
                        name="cgpa_input"
                        value={values.twelveth_marks}
                        disabled
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Family Income</p>
              </Col>
              <Col sm={9}>
                <Row>
                  {/* <Col
                  sm={1}
                  style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}
                >
                  <Form.Check
                    type={'checkbox'}
                    checked={sorting.family_income ? true : false}
                    defaultChecked={true}
                    onChange={() => handleSorting('family_income', !sorting.family_income)}
                    style={{ width: '25px', height: '25px' }}
                  />
                </Col> */}
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      {/* <Form.Select name="ig_id" value={filter.ig_id} onChange={handleFilter}>
                      <option value="">Select</option>
                      {incomeGroupList?.map((item)=>{
                        return(
                          <option key={item.ig_id} value={item.ig_id}>{item.ig_name}</option>    
                        )
                      })}
                    </Form.Select> */}

                      {/* <Form.Control
                      type="text"
                      name="family_income_input"
                      placeholder="0"
                      value={values.family_income_input}
                      onChange={handleChange}
                    /> */}
                      <Form.Select
                        name="cgpa_input"
                        value={values.family_income}
                        onChange={handleChange}
                        disabled
                      >
                        <option value="">Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">CGPA</p>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={1} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <Form.Check
                      type={'checkbox'}
                      checked={sorting.cgpa ? true : false}
                      onChange={() => console.log()}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </Col>
                
                </Row>
              </Col>
            </Row> */}
            {/* <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Financial Wellness</p>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={1} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <Form.Check
                      type={'checkbox'}
                      checked={sorting.financial_wellness ? true : false}
                      onChange={() => console.log()}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </Col>
               
                </Row>
              </Col>
            </Row> */}
            {/* <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Mobile Wallet Setup</p>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={1} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <Form.Check
                      type={'checkbox'}
                      checked={sorting.mobile_wallet_setup ? true : false}
                      onChange={() => console.log()}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </Col>
                 
                </Row>
              </Col>
            </Row> */}
            {/* <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">12th Marks</p>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={1} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <Form.Check
                      type={'checkbox'}
                      checked={sorting.twelth_marks ? true : false}
                      onChange={() => console.log()}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </Col>
                
                </Row>
              </Col>
            </Row> */}
            {/* <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Family Income</p>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={1} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <Form.Check
                      type={'checkbox'}
                      checked={sorting.family_income ? true : false}
                      onChange={() => console.log()}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </Col>
                 
                </Row>
              </Col>
            </Row> */}
            {/* <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Gender</p>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={1} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                    <Form.Check
                      type={'checkbox'}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </Col>
                 
                </Row>
                <div style={{ height: "10px" }}></div>
              </Col>

            </Row> */}
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

const ProgramSetup = (props) => {
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }
  const [courseFee, setCourseFee] = useState(0)
  const [associatedCost, setAssociatedCost] = useState(props.values.additional_expense)
  const [budgetEmarked, setBugetEmarkted] = useState(props.values.total_amount)
  const [seekingScholarship, setSeekingScholarship] = useState(0)
  const [beingSponsored, setBeingSponsored] = useState(props.values.sponsored_candidate_count)
  useEffect(() => {
    // Calculate Budget emarked
    let budget_emarked = (parseInt(courseFee) + parseInt(associatedCost)) * parseInt(beingSponsored)
    setBugetEmarkted(budget_emarked)
  }, [associatedCost, courseFee, beingSponsored])

  useEffect(() => {
    props.handleValue('additional_expense', associatedCost)
  }, [associatedCost])

  useEffect(() => {
    props.handleValue('seeking_scholarship', seekingScholarship)
  }, [seekingScholarship])

  useEffect(() => {
    props.handleValue('sponsored_candidate_count', beingSponsored)
  }, [beingSponsored])

  useEffect(() => {
    props.handleValue('total_amount', budgetEmarked)
  }, [budgetEmarked])

  useEffect(() => {
    getSubjects()
  }, [])
  const [subjectList, setSubjectList] = useState([])
  const [skillList, setSkillList] = useState([])

  const getSubjects = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjects',
      jwtHeader,
    })
      .then((response) => {
        setSubjectList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSkills = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjectSkills/' + id,
      jwtHeader,
    })
      .then((response) => {
        setSkillList(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  // Vendor
  const [vendorList, setVendorList] = useState([])
  const fetchVendor = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getVendorsByCourse/' + id,
      jwtHeader,
    })
      .then((response) => {
        setVendorList(response.data.Content)
      })
      .catch((error) => {})
  }

  const [courseList, setCourseList] = useState([])
  const fetchCourses = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCoursesBySubject/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response, 'Course list')
        setCourseList(response.data.Content)
      })
      .catch((error) => {})
  }

  const [paymentPlanList, setPaymentPlanList] = useState({})
  const fetchCourse = (id) => {
    axios
      .get(baseURL + 'getCourseById/' + id, jwtHeader)
      .then((response) => {
        // setPaymentPlanList(response.data)

        setCourseFee(response.data?.Content?.fees)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    console.log(props.values, 'PROPS PROGRAM SETUP')
    setValues(props.values)
    if (props.values.subject_id !== '') {
      getSubjects(props.values.subject_id)
      fetchCourses(props.values.subject_id)
      getSkills(props.values.subject_id)
    }
    if (props.values.course_id !== '') {
      fetchCourse(props.values.course_id)
      fetchVendor(props.values.course_id)
      applyFilter(props.values.course_id)
    }
    setAssociatedCost(props.values.additional_expense)
    // setBugetEmarkted(props.values.total_amount);
    setBeingSponsored(props.values.sponsored_candidate_count)
  }, [props.values])

  const [values, setValues] = useState(props.values)

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'subject_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['course_id']: '',
      }))
      fetchCourses(value)
    } else if (name === 'course_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['vendor_id']: '',
      }))
      fetchCourse(value)
      fetchVendor(value)
      applyFilter(value)
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
    props.handleValue(name, value)
  }
  const [filter, setFilter] = useState({})
  useEffect(() => {
    console.log(props.program.city_id)
    setFilter({
      // skill_id: props.program?.skill_id,
      ig_id: props.program?.ig_id,
      sport_id: props.program?.sport_id,
      pc_id: props.program?.pc_id,
      current_state_id: props.program?.current_state_id,
      city_id: props.program?.city_id,
      college_id: props.program?.college_id,
      gender: props.program?.gender,
    })
  }, [props.program])

  const applyFilter = (val) => {
    //alert("mmm")
    let filterValues = { ...filter, interested_course_id: val }
    axios({
      method: 'POST',
      url: baseURL + 'getEligibleCandidates',
      data: filterValues,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setSeekingScholarship(0)
      })
      .catch((error) => {
        console.log(error)
        // alert('Something went wrong. Please try again.')
      })
  }
  return (
    <>
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347', borderTop: '1px solid #ffffff' }}>
          <p className="labelText">Program Type</p>
        </Col>
        <Col sm={9} style={{ borderTop: '1px solid #000000', paddingTop: '5px' }}>
          <Form.Group className="mb-3">
            <Form.Select
              name="program_type"
              disabled
              value={values.program_type}
              onChange={handleChange}
            >
              <option value="">-Select-</option>
              <option value="0">Skilling</option>
              <option value="1">Scholarship</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {values.program_type == 0 && (
        <>
          {' '}
          {/* <Row>
            <Col sm={3} style={{ backgroundColor: "#002347" }}>
              <p className="labelText">Course Category</p>
            </Col>
            <Col sm={9}>
              <Form.Group className="mb-3">
                <Form.Select
                  name="course_category"
                  disabled
                  value={values.course_category}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {courseCategory.map((item, index) => {
                    return (
                      <option key={index} value={item.course_catg_id}>
                        {item.course_catg_name}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Subject</p>
            </Col>
            <Col sm={9}>
              <Form.Group className="mb-3">
                <Form.Select
                  name="subject_id"
                  disabled
                  value={values.subject_id}
                  onChange={handleChange}
                >
                  <option value="">Select Subject</option>
                  {subjectList.map((item, index) => {
                    return (
                      <option key={index} value={item.subject_id}>
                        {item.subject_name}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Skill</p>
            </Col>
            <Col sm={9}>
              <Form.Group className="mb-3">
                <Form.Select name="skill_id" value={values.skill_id} onChange={handleChange}>
                  <option value="">Select Skill</option>
                  {skillList.map((item, index) => {
                    return (
                      <option key={index} value={item.skill_id}>
                        {item.skill_name}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Name</p>
            </Col>
            <Col sm={9}>
              <Form.Group className="mb-3">
                <Form.Select name="course_id" value={values.course_id} onChange={handleChange}>
                  <option value="">Select</option>
                  {courseList?.map((item) => (
                    <option value={item.course_id} key={item.course_id}>
                      {item.course_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Fee</p>
            </Col>
            <Col sm={9}>
              <p className="valueText"> &#x20B9; {courseFee} </p>
            </Col>
          </Row>
        </>
      )}

      {/* {values.program_type === "1" ?
  <Row>
    <Col sm={3} style={{backgroundColor:'#002347'}}>
      <p className='labelText'>Amount Per Student</p>
    </Col>
    <Col sm={9}>
      <p className='valueText'>&#x20B9;{values?.amount_per_student}</p>
    </Col>  
  </Row>:null
} */}

      {/* {values.program_type === "1" &&  
          <Row>
            <Col sm={3} style={{backgroundColor:'#002347'}}>
              <p className='labelText'>Amount Per Student</p>
            </Col>
            <Col sm={9}>
              <p className='valueText'>&#x20B9;{values?.amount_per_student}</p>
            </Col>  
          </Row>
      } */}

      {/* <Row>
        <Col sm={3} style={{ backgroundColor: "#002347" }}>
          <p className="labelText">Vendor</p>
        </Col>
        <Col sm={9}>
          <Form.Group className="mb-3">
            <Form.Select name="vendor_id" disabled value={values.vendor_id} onChange={handleChange}>
              <option value="">Select Vendor</option>
              {vendorList?.map((item) => (
                <option value={item.vendor_id} key={item.vendor_id}>
                  {item.vendor}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row> */}

      {/* <Row>
        <Col sm={3} style={{ backgroundColor: "#002347" }}>
          <p className="labelText">Payment milestone</p>
        </Col>
        <Col sm={9}>
          {paymentPlanList?.PaymentData?.map((item, index) => {
            return (
              <Row key={index} style={{ borderBottom: "1px solid #ccc", paddingBottom: "5px", marginBottom: "5px" }}>
                <Col>Milestone {item.payment_milestone_nbr}</Col>
                <Col>&#x20B9; {item.amount_due}</Col>
              </Row>
            )
          })}
        </Col>
      </Row> */}
      {/* <Row>
        <Col sm={3} style={{ backgroundColor: "#002347" }}>
          <p className="labelText">Associated costs sponsored</p>
        </Col>
        <Col sm={9}>
          <Form.Group className="mb-3">
            <Form.Control
              type="number"
              name="associated_cost"
              placeholder="0"
              value={associatedCost}
              readOnly
              onChange={(e) => setAssociatedCost(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row> */}
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347' }}>
          {/* <p className="labelText">##Beneficiaries</p> */}
        </Col>
        <Col sm={9} style={{ paddingTop: '5px' }}></Col>
      </Row>
      {/* <Row>
        <Col sm={3} style={{ backgroundColor: "#002347" }}>
          <p className="labelText">Seeking scholarship</p>
        </Col>
        <Col sm={9}>
          <p className="valueText">{seekingScholarship}</p>
        </Col>
      </Row>
      <Row>
        <Col sm={3} style={{ backgroundColor: "#002347" }}>
          <p className="labelText">Being Sponsored</p>
        </Col>
        <Col sm={9}>
          <Form.Group className="mb-3">
            <Form.Control
              type="number"
              min={1} 
              name="being_sponsored"
              placeholder="0"
              value={beingSponsored}
              onChange={(e) => setBeingSponsored(e.target.value)}
              readOnly
            />
          </Form.Group>
        </Col>
      </Row> */}
      {/* <Row>
        <Col sm={3} style={{ backgroundColor: "#002347" }}>
          <p className="labelText">Budget emarked for program (&#x20B9;)</p>
        </Col>
        <Col sm={9}>
          <p className="valueText">{budgetEmarked}</p>
          <div style={{marginBottom:"10px"}}>
                {props.programLength > 1 ?
                <Button onClick={() => props.removeProgram()} className="primaryAdminButton" style={{marginRight:"10px"}} >Remove this Program</Button> : null }
                <Button onClick={() => props.addAnotherProgram()} className="primaryAdminButton" > + Add Another Program</Button>      
              </div>
        </Col>
      </Row> */}
    </>
  )
}
