/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import Swal from 'sweetalert2'
// import { Link, useHistory } from "react-router-dom"
const AddProgram = (props) => {
  const [values, setValues] = useState({
    program_status: '1',
    program_name: '',
    program_desc: '',
    sponsor_name: '',
    program_type: '0',
    target_beneficiaries: '',
    vendor_id: '',
    sponsor_id: '',
    course_id: '',
    course_fee: '',
    subject_id: '',
    skill_id: '',
    course_duration: '',
    total_amount: '',
    total_candidates_available: 0,
    eligible_candidates_count: 0,
  })
  const [filter, setFilter] = useState({
    skill_id: '',
    ig_id: '',
    sport_id: '',
    pc_id: '',
    current_state_id: '',
    city_id: '',
    college_id: '',
    gender: '',
    target_beneficiaries: '',
  })
  const [parentData, setParentData] = useState(null)
  const handleFilter = (e) => {
    const { name, value } = e.target
    let prev = { ...filter }

    if (name === 'current_state_id') {
      prev[name] = value
      prev['city_id'] = ''
      prev['college_id'] = ''
      fetchCities(value)
      fetchCollege(value)
    } else {
      prev[name] = value
    }

    setFilter(prev)
    applyFilter(prev)
  }

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const applyFilter = (filterValues) => {
    axios({
      method: 'POST',
      url: baseURL + 'getEligibleCandidates',
      data: filterValues,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        localStorage.setItem(
          'eligibleCandidates',
          JSON.stringify(response?.data?.EligibleCandidates),
        )
        // setEligibleCandidates(response.data.EligibleCandidates)
        setValues((prev) => ({
          ...prev,
          ['eligible_candidates_count']: response.data.EligibleCandidates.length,
          ['total_candidates_available']: response.data.TotalCandidates.length,
        }))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'current_state_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['city_id']: '',
      }))
      fetchCities(value)
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    fetchState()
    fetchCriteria()
    fetchIncomeGroup()

    if (props.isUpdate) {
      fetchProgram(props.program_id)
    }

    if (!props?.program) {
      applyFilter()
    }

    if (props.program.program_id) {
      setValues({
        program_id: props.program.program_id,
        program_name: props.program.program_name,
        program_desc: props.program.program_desc,
        program_status: props.program.program_status,
        sponsored_candidate_count: props.program.sponsored_candidate_count,
        target_beneficiaries: props.program.target_beneficiaries,
        state_id: props.program.state_id,
        predefined_program_ind: props.program.predefined_program_ind,
      })
    }
    const userData = JSON.parse(localStorage.getItem('user'))
    try {
      const parentData = JSON.parse(localStorage.getItem('parent_data'))

      setValues((prev) => ({
        ...prev,
        ['sponsor_name']:
          parentData === null
            ? ''
            : parentData.sponsor_catg_type === 'NGO'
            ? ''
            : parentData.sponsor_name,
        ['sponsor_id']: userData?.Sponsor.sponsor_id === null ? '' : userData?.Sponsor.sponsor_id,
      }))

      setParentData(parentData)
    } catch (e) {
      console.error(e)
    }

    if (props.id !== 0) {
      fetchProgram(props.id)
    } else if (props.program !== '') {
      let program_props = { ...props.program }

      if (program_props.sponsor_name === '') {
        try {
          const parentData = JSON.parse(localStorage.getItem('parent_data'))
          program_props['sponsor_name'] =
            parentData === null
              ? ''
              : parentData.sponsor_catg_type === 'NGO'
              ? ''
              : parentData.sponsor_name
          program_props['sponsor_id'] = parentData === null ? '' : parentData.sponsor_id
        } catch (e) {
          console.error(e)
        }
      }
      setValues(program_props)
      setFilter({
        skill_id: props.program.skill_id,
        ig_id: props.program.ig_id,
        sport_id: props.program.sport_id,
        pc_id: props.program.pc_id,
        current_state_id: props.program.current_state_id,
        city_id: props.program.city_id,
        college_id: props.program.college_id,
        gender: props.program.gender,
        target_beneficiaries: props.program.target_beneficiaries,
      })
    }
  }, [])

  const [stateList, setStateList] = useState([])
  const fetchState = () => {
    axios
      .get(baseURL + 'getStateList', jwtHeader)
      .then((response) => {
        setStateList(response.data.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // College
  const [collegeList, setCollegeList] = useState([])
  const fetchCollege = (current_state_id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCollegeMaster/' + current_state_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCollegeList(response.data.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // Performace Criteria
  const [criteriaList, setCriteriaList] = useState([])
  const fetchCriteria = () => {
    axios
      .get(baseURL + 'getPerformanceCriteria', jwtHeader)
      .then((response) => {
        setCriteriaList(response.data.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // Income Group
  const [incomeGroupList, setIncomeGroupList] = useState([])
  const fetchIncomeGroup = () => {
    axios
      .get(baseURL + 'getIncomeGroup', jwtHeader)
      .then((response) => {
        setIncomeGroupList(response.data.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // City List
  const [cityList, setCityList] = useState([])
  const fetchCities = (current_state_id) => {
    axios
      .get(baseURL + 'getCity/' + current_state_id, jwtHeader)
      .then((response) => {
        setCityList(response.data.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const successAction = () => {
    let values_option = { ...values, ...filter }
    if (values_option.program_name.trim() === '' || values_option.target_beneficiaries === '') {
      Swal.fire('Please fill required fields!', 'Required fields!', 'error')
    } else {
      if (props.id === 0) {
        props.setProgram(values_option)
      } else {
        values_option.program_id = props.id
        axios({
          method: 'POST',
          url: baseURL + 'setProgram',
          data: values_option,
          headers: { 'access-token': localStorage.getItem('csr_token') },
        })
          .then((response) => {
            alert('Successfully updated')
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }

  const fetchProgram = (id) => {
    debugger
    axios
      .get(baseURL + 'getProgramById/' + id, jwtHeader)
      .then((response) => {
        setValues(response.data.Content[0])
        setFilter({
          skill_id: response.data.Content[0].skill_id,
          ig_id: response.data.Content[0].ig_id,
          sport_id: response.data.Content[0].sport_id,
          pc_id: response.data.Content[0].pc_id,
          current_state_id: response.data.Content[0].current_state_id,
          city_id: response.data.Content[0].city_id,
          college_id: response.data.Content[0].college_id,
          gender: response.data.Content[0].gender,
          target_beneficiaries: response.data.Content[0]?.target_beneficiaries,
          // permanent_current_state_id:response.data.Content[0].permanent_current_state_id,
        })
        fetchCities(response.data.Content[0].current_state_id)
        fetchCollege(response.data.Content[0].current_state_id)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Name *</p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="program_name"
                    placeholder="Program Name"
                    value={values.program_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Description</p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="program_desc"
                    placeholder="Program Description"
                    value={values.program_desc}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Sponsored By *</p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    readOnly={
                      parentData === null
                        ? false
                        : parentData.sponsor_catg_type === 'NGO'
                        ? false
                        : true
                    }
                    name="sponsor_name"
                    placeholder="Sponsor Name"
                    value={values.sponsor_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Target Beneficiaries *</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="target_beneficiaries"
                    value={filter.target_beneficiaries}
                    onChange={handleFilter}
                  >
                    <option value="">Select Beneficiaries</option>
                    <option value="1">Students</option>
                    <option value="0">Ex-servicemen</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Selection Criteria</p>
              </Col>
              <Col sm={9}>
                <p className="formLabelText">Location Criteria</p>
                <Row style={{ borderBottom: '1px solid #ccc' }}>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>State</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="current_state_id"
                        value={filter.current_state_id}
                        onChange={handleFilter}
                      >
                        <option>Select State</option>
                        {stateList?.map((stateids) => (
                          <option value={stateids.state_id} key={stateids.state_id}>
                            {stateids.state_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>City</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="city_id"
                        value={filter.city_id}
                        onChange={handleFilter}
                        disabled={filter.college_id !== '' || filter.current_state_id === ''}
                      >
                        <option>Select City</option>
                        {cityList?.map((item) => (
                          <option value={item.city_id} key={item.city_id}>
                            {item.city_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Educational Institutions Name</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="college_id"
                        value={filter.college_id}
                        onChange={handleFilter}
                        disabled={filter.city_id !== '' || filter.current_state_id === ''}
                      >
                        <option>Select Institute</option>
                        {collegeList?.map((item) => (
                          <option value={item.college_id} key={item.college_id}>
                            {item.college_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <p className="formLabelText">Personal Criteria</p>
                <Row style={{ borderBottom: '1px solid #ccc' }}>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Gender</span>
                    <Form.Group className="mb-3">
                      <Form.Select name="gender" value={filter.gender} onChange={handleFilter}>
                        <option value="">Select</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Family Income (Annual)</span>
                    <Form.Group className="mb-3">
                      <Form.Select name="ig_id" value={filter.ig_id} onChange={handleFilter}>
                        <option value="">Select</option>
                        {incomeGroupList?.map((item) => {
                          return (
                            <option key={item.ig_id} value={item.ig_id}>
                              Less than {item.ig_name} Lacs
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <span style={{ fontSize: '12px' }}>Performance Criteria (CGPA)</span>
                    <Form.Group className="mb-3">
                      <Form.Select name="pc_id" value={filter.pc_id} onChange={handleFilter}>
                        <option value="">Select</option>
                        {criteriaList?.map((item) => {
                          return (
                            <option key={item.pc_id} value={item.pc_id}>
                              More than {item.pc_name}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }} className="pt-1">
                <p className="labelText">Eligible Beneficiaries Count</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3 mt-2">
                  <Form.Control
                    readOnly
                    type="text"
                    name="eligible_candidates_count"
                    placeholder="0"
                    value={values.eligible_candidates_count}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Total Beneficiaries Available</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    readOnly
                    type="text"
                    name="total_candidates_available"
                    placeholder="0"
                    value={values.total_candidates_available}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}
              className="mb-2"
            >
              <Button onClick={successAction} className="primaryAdminButton">
                {'Create Program'}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default AddProgram
