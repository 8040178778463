export const validateEmail = (mail) => {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
};

export const validatePhoneNumber = (input_str) => {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  return re.test(input_str);
};

export const validatePincode = (input_str) => {
  if (input_str === "") {
    return true;
  }
  var re = /^[1-9][0-9]{5}$/;

  return re.test(input_str);
};

export const validatePAN = (input_str) => {
  var re = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

  return re.test(input_str);
};
