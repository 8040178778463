import React, { useEffect } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import useManageProgram from './useManageProgram'

const FinanceDetails = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const sponsorId = searchParams.get('sponsor_id')

  const {
    course,
    values,
    virtualAccountDetails,
    handleChange,
    programDetails,
    fetchProgramDetail,
    commitFunds,
    isSubmitting,
  } = useManageProgram(sponsorId)

  useEffect(() => {
    fetchProgramDetail(searchParams.get('program_id'))
  }, [])

  return (
    <div className="container">
      <Row>
        <Col></Col>
        <Col md={8}>
          <div className="payment-confirmation-card mb-3">
            <div className="section">
              <p className="section-title mb-4">Program Details</p>
              <div className="flex">
                <div className="flex-1 label">Program Name</div>
                <div className="flex-1 value">{programDetails?.program_name}</div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Course Name</div>
                <div className="flex-1 value">{course?.course_name}</div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Course Description</div>
                <div className="flex-1 value">{course?.course_desc}</div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Course Fee</div>
                <div className="flex-1 value">&#x20B9; {course?.fees}</div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Course Duration</div>
                <div className="flex-1 value">
                  {course?.duration} {course?.duration_unit}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Service Partner</div>
                <div className="flex-1 value">{course?.institute_name}</div>
              </div>
            </div>
            <div className="section">
              <p className="section-title mb-4">Funds Committed & Account Balance</p>
              <div className="flex">
                <div className="flex-1 label">Planned Budget</div>
                <div className="flex-1 value">₹{programDetails?.planned_budget}</div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Current Committed Budget</div>
                <div className="flex-1 value">₹{programDetails?.committed_amount}</div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Carry Forward Extra Funds</div>
                <div className="flex-1 value">
                  {programDetails?.carry_forward_funds === 'Y' ? 'Yes' : 'No'}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Current Committed Budget</div>
                <div className="flex-1 value">₹{programDetails?.committed_amount}</div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Surplus in Virtual Account</div>
                <div className="flex-1 value">
                  ₹{virtualAccountDetails?.surplus_in_virtual_account}
                </div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Amount to be added in Virtual Account</div>
                <div
                  className={`flex-1 value ${
                    virtualAccountDetails?.amount_to_be_added_va === '0 (Sufficient Amount)'
                      ? 'sufficient'
                      : 'insufficient'
                  }`}
                >
                  ₹{virtualAccountDetails?.amount_to_be_added_va}
                </div>
              </div>
            </div>
            <div className="section">
              <p className="section-title mb-4">Details of CSR funds remitted (Optional)</p>
              <div className="flex">
                <div className="flex-1 label">Transfer Description</div>
                <div className="flex-1 value">
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="Transfer_Description"
                    value={values.Transfer_Description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Name of the Remitter</div>
                <div className="flex-1 value">
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="Name_of_the_remitter"
                    value={values.Name_of_the_remitter}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1 label">Date of Transaction</div>
                <div className="flex-1 value">
                  <Form.Control
                    type="date"
                    placeholder=""
                    name="Date_of_transaction"
                    value={values.Date_of_transaction}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex-1 label">UTR No</div>
                <div className="flex-1 value">
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="UTR_No"
                    value={values.UTR_No}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col></Col>
      </Row>
      {/* <Card>
        <Card.Header>Details of CSR funds remitted</Card.Header>
        <Card.Body style={{ padding: '0px 11px', marginTop: '10px' }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Program Name</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Program Name"
                      name="Program_Name"
                      value={values.Program_Name}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Transfer Amount</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Transfer Amount"
                      name="Transfer_Amount"
                      value={values.Transfer_Amount}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Transfer Description</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="Transfer_Description"
                      value={values.Transfer_Description}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Name of the Remitter</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="Name_of_the_remitter"
                      value={values.Name_of_the_remitter}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Date of Transaction</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="date"
                      placeholder=""
                      name="Date_of_transaction"
                      value={values.Date_of_transaction}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>

              <Col sm={6}>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">UTR No</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="UTR_No"
                      value={values.UTR_No}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Bank Name</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="Bank_Name"
                      value={values.Bank_Name}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">IFSC Code</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="IFSC_Code"
                      value={values.IFSC_Code}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Account Number</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="From_Account"
                      value={values.From_Account}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Payment to service partner</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Select
                      name="payment_to_service_partner"
                      value={values.payment_to_service_partner}
                      onChange={handleChange}
                    >
                      <option value="">-Select-</option>
                      <option value="Direct">
                        Direct{' '}
                        <span style={{ fontSize: '10px' }}>
                          (no beneficiary authorization required)
                        </span>
                      </option>
                      <option value="Indirect">
                        Indirect (credit to beneficiary wallet for authorizing payment to service
                        partner)
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              <div style={{ marginTop: '20px' }}>
                <p style={{ fontSize: '11px' }}>
                  <b>Note:</b>Please fill these fields carefully. You'll not be able to update, once
                  submitted.
                </p>
              </div>
              
            </Row>
          </Form>
        </Card.Body>
      </Card> */}
      <div className="flex justify-center items-center gap-2">
        <Button
          style={{ width: '100px', backgroundColor: '#002347', borderColor: '#002347' }}
          type="button"
          disabled={virtualAccountDetails?.amount_to_be_added_va !== '0 (Sufficient Amount)'}
          onClick={() => commitFunds(programDetails)}
        >
          {isSubmitting ? (
            <img alt="spinner" src="/loaders/spinner_red.gif" style={{ height: '20px' }} />
          ) : (
            'Submit'
          )}
        </Button>
        <Button
          className="outline-button px-4"
          onClick={() => history.replace('/sponsor/dashboard')}
        >
          Skip
        </Button>
      </div>
    </div>
  )
}

export default FinanceDetails
