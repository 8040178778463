import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../../config/baseURLPath";

import "../VirtualAccountDetails.css";
import CustomInputField from "../../CustomInputField";
import PrimaryButton from "../../PrimaryButton";

const BLANK_REQUEST = {
  name: "",
  bank_name: "",
  account_number: "",
  ifsc_code: "",
};

const AddBankAccount = ({ show, setActiveTab }) => {
  const [values, setValues] = useState(BLANK_REQUEST);
  const [error, setErrors] = useState(null);

  const handleChange = (name, val) => {
    if (error !== null) {
      setErrors(null);
    }
    setValues({ ...values, [name]: val });
  };

  const addBankAccount = async () => {
    if (values?.name?.trim() === "") {
      setErrors({ name: "Please enter name" });
      return;
    }
    if (values?.bank_name?.trim() === "") {
      setErrors({ bank_name: "Please enter bank name" });
      return;
    }
    if (values?.account_number?.trim() === "") {
      setErrors({ account_number: "Please enter Account No." });
      return;
    }
    if (values?.ifsc_code?.trim() === "") {
      setErrors({ ifsc_code: "Please enter IFSC code" });
      return;
    }
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    };

    let reqParams = { ...values, ...show };

    await axios
      .post(baseURL + "saveNewAccount", reqParams, jwtHeader)
      .then(function (response) {
        if (response.data?.Data?.success) {
          setValues(BLANK_REQUEST);
          setErrors({
            msg: "Account details successfully added",
            type: "success",
          });
          setTimeout(() => {
            setActiveTab(2);
          }, 3000);
        } else {
          setErrors({
            msg: response.data?.Message || "No record found on TrustMore",
            type: "warning",
          });
        }
      })
      .catch(function (error) {});
  };

  return (
    <div>
      {error?.msg && <Alert variant={error?.type}>{error?.msg}</Alert>}
      <div className="flex">
        <div className="label-column">
          Bank Account Holder Name <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomInputField
            error={error?.name}
            name="name"
            type={"text"}
            placeholder=""
            value={values.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column">
          Bank Name <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomInputField
            error={error?.bank_name}
            name="bank_name"
            type={"text"}
            placeholder=""
            value={values.bank_name}
            onChange={(e) => handleChange("bank_name", e.target.value)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column">
          Account No. <span className="required">*</span>
        </div>
        <div className="value-column">
          <CustomInputField
            error={error?.account_number}
            name="account_number"
            type={"text"}
            placeholder=""
            value={values.account_number}
            onChange={(e) => handleChange("account_number", e.target.value)}
          />
        </div>
      </div>
      <div className="flex">
        <div className="label-column border-bottom">
          IFSC Code <span className="required">*</span>
        </div>
        <div className="value-column border-bottom">
          <CustomInputField
            error={error?.ifsc_code}
            name="ifsc_code"
            type={"text"}
            placeholder=""
            value={values.ifsc_code}
            onChange={(e) => handleChange("ifsc_code", e.target.value)}
          />
        </div>
      </div>
      <div className="p-2 flex justify-center">
        <PrimaryButton
          label="Add"
          additionalStyle={{ width: "100px" }}
          onClick={addBankAccount}
        />
      </div>
    </div>
  );
};

export default AddBankAccount;
