import React from "react";
import Form from "react-bootstrap/Form";

const CustomInputField = ({
  label,
  type = "text",
  name,
  placeholder = "Write...",
  value,
  onChange,
  error,
  disabled = false,
  min,
  max,
  addSpace = false,
  required = false,
  maxLength = "",
  accept = "",
  alignText = "",
}) => {
  return (
    <div className={addSpace ? "mb-2" : undefined}>
      <Form.Group
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        {label && (
          <Form.Label>
            {label} {required && <span style={{ color: "#ff0000" }}>*</span>}
          </Form.Label>
        )}
        <Form.Control
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          className={disabled ? "h-39px" : "bg-white h-39px"}
          onChange={onChange}
          isInvalid={error}
          style={{
            backgroundColor: disabled ? "#e6e6e6" : "transparent",
            textAlign: alignText || "left",
          }}
          disabled={disabled}
          min={min}
          max={max}
          maxLength={maxLength}
          accept={accept}
        />
        {error && (
          <Form.Control.Feedback
            type="invalid"
            style={{ fontSize: "10px", marginLeft: "4px" }}
          >
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </div>
  );
};

export default CustomInputField;
