import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { NativeBaseProvider, extendTheme } from "native-base";
import "bootstrap/dist/css/bootstrap.min.css";
//import App from "./App";
import Routes from "./routes";

//import reportWebVitals from "./reportWebVitals";
import getStore from "./store";
const store = getStore();

const theme = extendTheme({
  fonts: {
    body: "'Montserrat', sans-serif",
    regular: "'Montserrat Regular', sans-serif",
    medium: "'Montserrat Medium', sans-serif",
    semiBold: "'Montserrat Semi-Bold', sans-serif",
    bold: "'Montserrat Bold', sans-serif",
  },
  colors: {
    primary: {
      50: "#e5f2ff",
      100: "#e5f2ff",
      200: "#b3d8ff",
      300: "#80beff",
      400: "#4da4ff",
      500: "#1a8bff",
      600: "#0071e6",
      700: "#0058b3",
      800: "#003f80",
      900: "#00264d",
      1000: "#002347",
    },
    blue: "#002347",
    orange: "#FF9635",
    grey: "#446C96",
    white: "#FFFFFF",
    background: "#F5F9FE",
  },
  fontSizes: {
    "52px": "52px",
    "20px": "20px",
    "28px": "28px",
    "18px": "18px",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <Routes />
    </NativeBaseProvider>
  </Provider>
  // </React.StrictMode>
);
