import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import "./VirtualAccountDetails.css";
import VirtualAccount from "./components/VirtualAccount";
import AddBankAccount from "./components/AddBankAccount";
import AddedBankAccounts from "./components/AddedBankAccounts";

const TABS = ["VIRTUAL ACCOUNT", "ADD BANK ACCOUNT", "ALL BANK ACCOUNTS"];

const VirtualAccountDetails = ({ handleClose, show }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="virtual-modal-heading">Virtual Account Details</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="virtual-account-modal">
          {TABS?.map((tab, index) => {
            return (
              <Tab
                index={index}
                key={index.toString()}
                item={tab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            );
          })}
        </div>
        {activeTab === 0 && <VirtualAccount show={show} />}
        {activeTab === 1 && (
          <AddBankAccount show={show} setActiveTab={setActiveTab} />
        )}
        {activeTab === 2 && <AddedBankAccounts show={show} />}
      </Modal.Body>
    </Modal>
  );
};

export default VirtualAccountDetails;

const Tab = ({ index, item, activeTab, setActiveTab }) => {
  return (
    <button
      className={`tab ${index === activeTab ? "active" : ""}`}
      onClick={() => setActiveTab(index)}
    >
      {item}
    </button>
  );
};
