import React from "react";
import "./common.css";

const PrimaryButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      className="new-primary-button"
      style={props?.additionalStyle}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};

export default PrimaryButton;
