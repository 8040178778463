import { useState } from 'react'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

const BANK_VA_DETAILS = {
  amount_committed: 0,
  account_balance: 0,
  future_payments: 0,
  surplus_in_virtual_account: 0,
  amount_to_be_added_va: 0,
}

const BLANK_FINANCE_DETAIL = {
  Program_Id: '',
  Program_Name: '',
  Name_of_the_remitter: '',
  Transfer_Amount: '',
  Transfer_Description: '',
  Date_of_transaction: '',
  UTR_No: '',
  Bank_Name: '',
  From_Account: '',
  Beneficiary_Name: '',
  IFSC_Code: '',
  Bank_Account_no: '',
  payment_to_service_partner: '',
}

const useManageProgram = (sponsorId) => {
  const history = useHistory()
  const [course, setCourse] = useState({})
  const [values, setValues] = useState(BLANK_FINANCE_DETAIL)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [programDetails, setProgramDetails] = useState({})
  const [virtualAccountDetails, setVirtualAccountDetails] = useState(BANK_VA_DETAILS)
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const fetchVirtualAccountDetails = async (programData) => {
    let userID = localStorage.getItem('user_id')
    let obj = {
      user_id: userID,
      user_type: 'sponsor',
      pending_payment_amount: true,
    }
    try {
      let response = await axios.post(baseURL + 'fetchVirtualAccountDetails', obj, jwtHeader)
      if (response.data?.Data?.success) {
        let accountBalance = parseFloat(response.data?.Data?.data?.balance || 0)
        let futurePayments = parseFloat(response?.data?.PendingPayment[0]?.future_payments || 0)
        let surplusToVA = accountBalance - futurePayments
        let amountCommitted =
          programData.carry_forward_funds === 'Y'
            ? parseFloat(programData.planned_budget || 0)
            : parseFloat(programData.total_amount || 0)
        let amountToBeAddedInVA = amountCommitted - surplusToVA
        let ele = {
          account_balance: accountBalance,
          future_payments: futurePayments,
          surplus_in_virtual_account: surplusToVA,
          amount_to_be_added_va:
            amountToBeAddedInVA < 0 ? `0 (Sufficient Amount)` : amountToBeAddedInVA,
          amount_committed: amountCommitted,
        }
        return ele
      } else {
        return BANK_VA_DETAILS
      }
    } catch (e) {
      console.error(e)
      return BANK_VA_DETAILS
    }
  }

  const fetchCourse = (id) => {
    axios
      .get(baseURL + 'getCourseById/' + id, jwtHeader)
      .then((response) => {
        setCourse(response.data?.Content)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const fetchProgramDetail = async (programId) => {
    const response = await axios.get(`${baseURL}getProgramById/${programId}`, {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
    if (response.data.Content?.length > 0) {
      setProgramDetails(response.data.Content?.length > 0 ? response.data.Content[0] : {})
      fetchCourse(response.data.Content[0].course_id)
      setValues({
        ...values,
        Program_Id: response.data.Content[0].program_id,
        Program_Name: response.data.Content[0].program_name,
        Transfer_Amount: response.data.Content[0].amount_to_start_program,
      })
      let vaDetails = await fetchVirtualAccountDetails(response.data.Content[0])
      setVirtualAccountDetails(vaDetails)
    }
  }

  const handleSubmit = async (programData) => {
    // if (values.Name_of_the_remitter?.trim() === '') {
    //   alert('Please enter remitter name')
    //   return
    // } else if (values.Date_of_transaction === '') {
    //   alert('Please enter transaction date.')
    //   return
    // } else if (values.UTR_No === '') {
    //   alert('Please enter UTR number')
    //   return
    // } else if (values.Bank_Name === '') {
    //   alert('Please enter bank name')
    //   return
    // } else if (values.IFSC_Code === '') {
    //   alert('Please enter ifsc')
    //   return
    // } else if (values.From_Account === '') {
    //   alert('Please enter account number')
    //   return
    // } else if (values.payment_to_service_partner === '') {
    //   alert('Please select payment type.')
    //   return
    // }

    try {
      const data = {
        iud_flag: 'I',
        program_id: programData?.program_id,
        sponsor_id: programData.sponsor_id,
        course_id: programData?.course_id,
        exp_catg_id: null,
        beneficiary_id: null,
        utr_no: values.UTR_No,
        ifsc_code: values.IFSC_Code,
        bank_name: values.Bank_Name,
        description: values.Transfer_Description,
        vendor_id: null,
        amount: values.Transfer_Amount,
        disbursed_amt_date: values.Date_of_transaction,
        payment_to_service_partner: values.payment_to_service_partner,
        name_of_remitter: values.Name_of_the_remitter,
        bank_account_no: values.From_Account,
      }

      await axios.post(`${baseURL}setProgramFundsDisbursed`, data, jwtHeader)
      Swal.fire('Success', 'Funds successfully transferred.', 'success')
      history.replace('/sponsor/dashboard')
    } catch (error) {
      console.error('Error submitting finance details:', error)
      Swal.fire('Oops!', 'Something went wrong.', 'error')
    }
  }

  const commitFunds = async (programData) => {
    setIsSubmitting(true)
    let requestParams = {
      sponsor_id: programData?.sponsor_id,
      program_id: programData?.program_id,
      course_id: programData?.course_id,
      amount: programData?.amount_to_start_program,
      use_surplus_amount: true, //programData?.carry_forward_funds === 'Y' ? true : false,
    }

    let response2 = await axios({
      method: 'POST',
      url: baseURL + 'addProgramCommittedFunds',
      data: [requestParams],
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })

    if (response2) {
      handleSubmit(programData)
    } else {
      setIsSubmitting(false)
      Swal.fire('Oops!', 'Something went wrong.', 'error')
    }
  }

  return {
    course,
    values,
    fetchVirtualAccountDetails,
    fetchCourse,
    handleChange,
    programDetails,
    fetchProgramDetail,
    handleSubmit,
    virtualAccountDetails,
    commitFunds,
    isSubmitting,
  }
}

export default useManageProgram
